'use strict';

$(() => {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.btn-page').on('click', function () {

        const id = $(this).attr('data-id');
        $('#idservice').val(id);
    })

    $('#policy').on("change", function () {
        $(this).val($(this).prop("checked") ? 1 : 0);
    });

    const messageContactEs = $('html').attr('lang') == 'es' ? {
        messages: {
            'name': {
                required: 'El campo nombre y apellidos es obligatorio',
                maxlength: 'El campo no puede tener mas de 120 caracteres',
            },
            'email': {
                required: 'El campo correo eletrónico es obligatorio',
                email: 'El campo debe contener un correo electrónico',
                maxlength: 'El campo no puede tener mas de 120 caracteres',
            },
            'phone': {
                required: 'El campo número de teléfono es obligatorio',
                number: 'El campo debe contener números',
                maxlength: 'El campo no puede tener mas de 15 caracteres',
                minlength: 'El campo no puede tener menos de 9 caracteres',
            },
            'comment': {
                required: 'El campo comentario es obligatorio',
                maxlength: 'El campo no puede tener mas de 1200 caracteres',
            },
            'policy': {
                required: 'Debe aceptar la política de privacidad para enviar los datos',
            }
        }
    } : {};

    $('#contact-form-data').validate({
        rules: {
            'name': {
                required: true,
                maxlength: 120,
            },
            'email': {
                required: true,
                email: true,
                maxlength: 120,
            },
            'phone': {
                required: true,
                number: true,
                maxlength: 15,
                minlength: 9,
            },
            'comment': {
                required: true,
                maxlength: 1200,
            },
            'policy': {
                required: true
            },
        },
        ...messageContactEs,
        errorPlacement: function (error, element) {
            const el = $(element);
            el.parent().after(error)
        }
    });

    $('#contact-send').on('click', function () {
        grecaptcha.ready(() => {

            const form = $('#contact-form-data');

            if (!form.valid()) return;
            grecaptcha.execute(reCAPTCHA_site_key, { action: 'CONTACT' }).then((token) => {

                let formData = form.serializeArray();
                formData.push({ name: "g-recaptcha-response", value: token });
                showLoaded();

                $.post('/contact', formData).done((res) => {
                    Swal.fire(
                        {
                            title: res.title,
                            text: res.message,
                            icon: 'success',
                        }
                    ).then(() => {
                        $('#contact-modal').modal('hide');
                        $('#contact-form-data').trigger('reset');
                    });
                }).fail((err) => {
                    let errors = err.responseJSON;
                    let list = '';

                    if (errors.hasOwnProperty('errors')) {
                        errors = errors.errors;
                        for (const key in errors) {
                            if (Object.hasOwnProperty.call(errors, key)) {
                                const element = errors[key].map((text) => `<p>${text}</p>`).join('');
                                list += element;
                            }
                        }
                    } else if (errors.hasOwnProperty('message')) {
                        list = errors.message;
                    } else {
                        list = 'Error 500';
                    }

                    Swal.fire(
                        {
                            title: 'Error',
                            html: list,
                            icon: 'error',
                        }
                    )
                }).always(() => {
                    hideLoaded();
                });
            });
        });
    });

    function showLoaded() {
        $('body').append('<div id="bg-load"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>');
    }
    function hideLoaded() {
        $('#bg-load').remove();
    }

    const myModalEl = document.getElementById('contact-modal')
    myModalEl.addEventListener('hidden.bs.modal', function (event) {
        $('#contact-form-data').trigger('reset');
        $('#titleContact').html($('#titleContact').attr('data-old'));
        $('#contact-modal label.error').remove();
    })
});